import * as short from 'short-uuid'

const shortUUID = () => {

const translator = short()
const flickrTranslator = short(short.constants.flickrBase58)
const uuid = translator.uuid()
const flickrID = flickrTranslator.fromUUID(uuid)

return flickrID

}

export default shortUUID