import PouchDB from 'pouchdb'
import pouchdbFind from 'pouchdb-find'
import axios from 'axios'
PouchDB.plugin(pouchdbFind)

const baseURL = 'https://caselinkdb.wishfarms.com/couchdb/'

export async function getUsers() {
    try {
        const db = new PouchDB(`${baseURL}_users`)

        const results = await db.find({
            selector: {
                type: 'user'
            },
            fields: ['name']
        })
        return results.docs
    } catch (err) {
        console.log(err)
    }
}

export async function getUserData() {
    try {
        const db = new PouchDB(`${baseURL}_users`)

        const results = await db.find({
            selector: {
                type: 'user'
            },
            fields: ['name', 'companyID', 'companyName', 'locationID', 'language']
        })
        return results.docs
    } catch (err) {
        console.log(err)
    }
}

export async function getCompanies() {
    try {
        const db = new PouchDB(`${baseURL}facilities`)

        const results = await db.find({
            selector: {
                type: 'company'
            },
            fields: ['_id', 'companyID', 'locations']
        })
        
        if (results.docs) {
            const data = results.docs.map((row) => {
                return { companyID: row.companyID, companyName: row._id, locations: row.locations }
            })
            return data
        }
    } catch (err) {
        console.log(err)
    }
}

export async function addUser(userName, password, companyID, companyName, locationID, language) {
    try {
        const response = await axios({
            method: 'PUT',
            url: `${baseURL}_users/org.couchdb.user:${userName}`,
            data: {
                "name": userName,
                "password": password,
                "roles": ["user"],
                "type": "user",
                "companyID": companyID,
                "companyName": companyName,
                "locationID": locationID,
                "settings": {
                    "language": language
                }
            },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            timeout: 15000,
            withCredentials: true
        })

        if (response) {
            const newLabelDB = await createRemoteDB(userName, `labels_${userName}`)
            if (newLabelDB) {
                const { status, statusText, data } = response
                return {status, statusText, data}
            }
            
        }
    } catch (err) {
        console.log(err)
        throw err
    }
}

export async function createRemoteDB(userName, dbName) {
    const db = new PouchDB(`${baseURL}${dbName}`)
    const dbInfo = await db.info()

    if (dbInfo) {
        const response = await axios({
            method: 'PUT',
            url: `${baseURL}${dbName}/_security`,
            data: {
                "admins": {
                    "names": [userName],
                    "roles": ["_admin"]
                },
                "members": {
                    "names": [],
                    "roles": ["_user"]
                }
            },
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            timeout: 15000,
            withCredentials: true
        })

        if (response) {
            return response
        }
    }
}