import React, { useState, useRef, useEffect } from 'react'
import { useUser } from '../../contexts/UserCtx'
import { useStore } from '../../Store'
import { couchAuth } from '../../utils/server/requests'
import {getFacility} from '../../utils/database/dbFunction'
import '../../css/LoginWindow.css'
import logo from '../../images/WF_logo_PNG.png'

const LoginContainer = () => {

    //set ref on username input field to reset focus on invalid login
    const nameRef = useRef()
    //load user context and store context
    const user = useUser()
    const globalState = useStore()
    //declare username & password state vars for input fields
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    //declare state var for if there is a login error to show error message
    const [loginError, setLoginError] = useState('')

    //function to update changes to input fields in state 
    const handleChange = (event) => {
        if (event.target.name === 'name') {
            setUserName(event.target.value)
            if (loginError) {
                setLoginError('')
            }
        }
        if (event.target.name === 'password') {
            setPassword(event.target.value)
        }
    }

    //function to submit login form data to server and authenticate
    const handleSubmit = async (event) => {
        event.preventDefault()
        try { 
            const sanitizedName = userName.toLowerCase()
            const couch = await couchAuth({username: sanitizedName, password: password})
            if (couch) {
                if (couch.status === 200) {
                globalState.dispatch({type: 'START_LOADING'})
                
                    const facilityData = await getFacility(sanitizedName)
                    if (facilityData) {
                        setTimeout(() => {
                        const { facilityID, facilityName } = facilityData
                        user.dispatch({type: 'LOGIN', userName: sanitizedName, userType: 'user', facilityID, facilityName, authorized: true, loginDateTime: new Date() })
                        globalState.dispatch({type: 'END_LOADING'})
                    }, 1000)
                    } else {
                        if (sanitizedName === 'admin') {
                            user.dispatch({type: 'LOGIN', userName: sanitizedName, userType: 'admin', facilityID: 999, facilityName: 'Admin Facility', authorized: true, loginDateTime: new Date() })
                            globalState.dispatch({type: 'END_LOADING'})
                            return
                        }
                        console.log('Error: no facility ID found')
                    }
                    
                }
            
                if (couch.status === 401) {
                    setLoginError('Invalid username or password')
                    setPassword('')
                    setUserName('')
                    document.getElementById(nameRef.current.id).focus()
                }
            }
            if (!couch) {
                setLoginError('Network error:  Check internet connection')
                document.getElementById(nameRef.current.id).focus()
            }
        } catch (err) {
            throw err
        } 
    }

    useEffect(() => {
        document.getElementById(nameRef.current.id).focus()
    }, [])

    return (
        <form 
            className='LoginContainer'
            id='loginForm'
            name='loginForm' 
            onSubmit={(e) => handleSubmit(e)}
        >
            <div className='LoginLogo'>
                <img src={logo} alt='Wish Farms Logo' />
            </div>
            <div className='LoginInputUserContainer'>
                <input 
                    className='LoginInputUser InputNoAppend'
                    name='name'
                    id='name'
                    ref={nameRef} 
                    type='text'
                    placeholder='Username...'
                    onChange={(e) => handleChange(e)}
                    value={userName}
                    required={true}
                />
            </div>
            {loginError && (
                <div className='LoginError'>{loginError}</div>
            )}
            <div className='LoginInputPasswordContainer'>
                <input 
                    className='LoginInputPassword InputNoAppend'
                    name='password'
                    id='password' 
                    type='password'
                    placeholder='Password...'
                    onChange={(e) => handleChange(e)}
                    value={password}
                    required={true} 
                />
            </div>
            <button className='LoginButtonSubmit Button Shadow'>
                Log In
            </button>
        </form>
    )
}

export default LoginContainer