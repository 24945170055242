import React, { useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useLanguage } from '../../contexts/LanguageCtx'
import HistoryTable from './HistoryTable'
import '../../css/HistoryWindow.css'
import { IoMdArrowRoundForward, IoMdArrowRoundBack } from 'react-icons/io'


const History = () => {

    const [offset, setOffset] = useState(0)
    const [rowTotal, setRowTotal] = useState(0)
    const buttonNext = useRef()
    const language = useLanguage()
    const activeLanguage = {...language.state}

    const handleNext = () => {
        setOffset(offset + 10)
    }

    const handlePrevious = () => {
        setOffset(offset - 10)
    }

    useEffect(() => {
        setTimeout(() => {
        buttonNext.current.focus()
    }, 100)
    }, [buttonNext])

    return (
        <motion.div 
            className='HistoryContainer'
            key='HistoryContainer'
            initial={{x: "-200%"}}
            animate={{x: 0}}
            exit={{x: "-200%" }}
            transition={{ 
                duration: .5
            }}
        >
            <div className='HistoryTitle Title'>{activeLanguage.TabHistory}</div>
            <div className='HistoryWindow'>
                <HistoryTable offset={offset} setRowTotal={setRowTotal} />
            </div>
            <div className='HistoryControls'>
                <button
                    className='Button ButtonHistoryPrevious Shadow'
                    id='ButtonHistoryPrevious'
                    onClick={handlePrevious}
                    disabled={offset === 0}
                ><div><IoMdArrowRoundBack /></div><div>{activeLanguage.ButtonBack}</div></button>
                <button 
                    className='Button ButtonHistoryNext Shadow'
                    onClick={handleNext}
                    ref={buttonNext}
                    disabled={rowTotal < 10}
                ><div>{activeLanguage.ButtonNext}</div><div><IoMdArrowRoundForward /></div></button>
            </div>
               
        </motion.div>   
    )
}

export default History