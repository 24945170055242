// Packages
import React, { useRef, useEffect } from 'react'
import { IconContext } from 'react-icons'
import { MdCancel, MdNoteAdd, MdDone } from 'react-icons/md'
import { motion } from 'framer-motion'

// Contexts
import { useStore } from '../../Store'
import { useLanguage } from '../../contexts/LanguageCtx'

// Components
import InputTable from './InputTable'

// Utility functions
import codeToCount from '../../utils/codeToCount'
import shortUUID from '../../utils/shortUUID'

// Styles
import '../../css/InputWindow.css'

const InputContainer = () => {
    //Set Refs
    const firstRef = useRef(null)
    const lastRef = useRef(null)
    const addCaseRef = useRef(null)

    //Set State
    const globalState = useStore()
    const { dispatch, state } = globalState
    const { firstURL, lastURL, firstCount, lastCount, totalLabels, rows } = state
    const language = useLanguage()
    const activeLanguage = {...language.state}
    
    //Framer Motion animations
    const buttonHover = {
        scale: 1.05,
        transition: { duration: .25 }
    }
    const buttonTap = {
        scale: 0.95
    }
    /* const appendHover = {
        stroke: 'black',
        fill: 'yellow',
        transition: { duration: .25 }
    } */

    //Set Handlers
    const handleSubmit = (event) => {
        
        event.preventDefault()
        const caseID = shortUUID()
        if (caseID) {
            dispatch({type: 'GENERATE_LABEL', caseID})
            dispatch({type: 'UPDATE_FIRSTREF', refObj: firstRef})
        }
        
    }

    const handleChange = (event) => {

        if (firstURL.length === 31) {
            document.getElementById(lastRef.current.id).focus()
        }
        if (firstURL.length === 31 && lastURL.length === 31) {
            document.getElementById(addCaseRef.current.id).focus()
        }
        if (event.target.name === 'firstURL') {
            dispatch({type: 'UPDATE_FIRST_URL', firstURL: event.target.value })
        }
        if (event.target.name === 'lastURL') {
            dispatch({type: 'UPDATE_LAST_URL', lastURL: event.target.value})
        }
        
    }

    const handleClear = (event) => {

        if (event.target.name === 'clearFirst') {
            dispatch({type: 'UPDATE_FIRST_URL', firstURL: ''})
            document.getElementById(firstRef.current.id).focus()
        }
        if (event.target.name === 'clearLast') {
            dispatch({type: 'UPDATE_LAST_URL', lastURL: ''})
            document.getElementById(lastRef.current.id).focus()
        }
        
    }

    const handleClearRow = (index) => {
        
        const newTotal = totalLabels - rows[index].quantity
        dispatch({type: 'DELETE_ROW', index, newTotal})
    }

    const handleAdd = (event) => {
        
        const firstCode = firstURL.slice(-16)
        const lastCode = lastURL.slice(-16)
        if (firstCode.length === 16 && lastCode.length === 16) {
            dispatch({type: 'UPDATE_COUNT', firstCount: codeToCount(firstCode), lastCount: codeToCount(lastCode)})
        }
        document.getElementById(firstRef.current.id).focus()
        
    } 

    useEffect(() => {
        
        if (firstURL && lastURL && lastCount >= firstCount) {
            const quantity = lastCount - firstCount + 1
            if (quantity > 0) {
                const newTotal = totalLabels + quantity
                dispatch({type: 'ADD_ROW', newTotal, rowData: {firstCode: firstURL.slice(-16), lastCode: lastURL.slice(-16), quantity}})
            }
        } else if (firstURL && lastURL && lastCount < firstCount) {
            const quantity = firstCount - lastCount + 1
            if (quantity > 0) {
                const newTotal = totalLabels + quantity
                dispatch({type: 'ADD_ROW', newTotal, rowData: {firstCode: firstURL.slice(-16), lastCode: lastURL.slice(-16), quantity}})
            }
        }
    }, [firstCount, lastCount])

    useEffect(() => {
        if (firstURL.length === 31) {
            document.getElementById(lastRef.current.id).focus()
        }
        if (firstURL.length === 31 && lastURL.length === 31) {
            document.getElementById(addCaseRef.current.id).focus()
        }
    }, [firstURL, lastURL])

    return (
        <IconContext.Provider value={{className: 'iconProps'}}>
        <motion.div 
            className='InputContainer'
            key='InputForm'
            initial={{x: "-200%"}}
            animate={{x: 0}}
            exit={{x: "-200%"}}
            transition={{ 
                duration: .5
            }}
        >
            {activeLanguage.TitleCaseWindow && activeLanguage.TitleCaseWindow.length < 15 ? (
                <div 
                    className='InputTitle Title'
                >{activeLanguage.TitleCaseWindow}</div>
                ) : (
                <div 
                    className='InputTitle TitleLong'
                >{activeLanguage.TitleCaseWindow}</div>
                )}
            <form 
                className='InputForm'
                onSubmit={handleSubmit}
                id='InputForm'
                >
                <div className='FirstURLContainer'>
                    <input 
                        className='InputFirstURL Input'
                        name='firstURL'
                        id='firstURL'
                        ref={firstRef} 
                        type='text'  
                        autoFocus={true}
                        placeholder={`${activeLanguage.LabelFirstLabel}...`}
                        onChange={(e) => handleChange(e)}
                        onPaste={(e) => handleChange(e)}
                        value={firstURL}
                        minLength={16}
                        maxLength={31}
                        />
                    <button
                        className='ButtonClearFirstURL ButtonAppend'
                        name='clearFirst' 
                        type='button' 
                        onClick={(e) => handleClear(e)}
                        disabled={!firstURL}
                        >{firstURL === '' ? (<MdCancel size='2.5vh' color='gray' />) : (<MdCancel size='2.5vh' />)}
                    </button>
                </div>
                <div className='LastURLContainer'>
                    <input 
                        className='InputLastURL Input'
                        name='lastURL'
                        id='lastURL'
                        ref={lastRef} 
                        type='text'
                        placeholder={`${activeLanguage.LabelLastLabel}...`}
                        onChange={(e) => handleChange(e)}
                        value={lastURL}
                        minLength={16}
                        maxLength={31}
                    />
                    <button
                        className='ButtonClearLastURL ButtonAppend'
                        name='clearLast' 
                        type='button' 
                        onClick={(e) => handleClear(e)}
                        disabled={!lastURL}
                        >{lastURL === '' ? (<MdCancel size='2.5vh' color='gray' />) : (<MdCancel size='2.5vh' />)}
                    </button>
                </div>
                <motion.button
                    whileHover={buttonHover} whileTap={buttonTap} 
                    className='ButtonAddToCase Button Shadow'
                    name='addToCase'
                    id='addToCase'
                    ref={addCaseRef} 
                    type='button' 
                    onClick={(e) => handleAdd(e)}
                    disabled={firstURL.length > 31 || lastURL.length > 31 || firstURL.length < 16 || lastURL.length < 16}
                    >
                    <MdNoteAdd size='5vh' />{activeLanguage.ButtonAddToCase}
                </motion.button>
                <InputTable rows={rows} onRemove={handleClearRow} />
                
            </form>
            <div className='InputControls'>
                <motion.button 
                    whileHover={buttonHover} whileTap={buttonTap}
                    className='ButtonFinishCase Button Shadow' 
                    type='submit'
                    form='InputForm'
                    disabled={rows.length === 0}
                    >
                    <MdDone size='5vh' />{activeLanguage.ButtonPreview}
                </motion.button>
            </div>
        </motion.div>
        </IconContext.Provider>
    )
}

export default InputContainer