import React, {createContext, useContext, useReducer} from 'react'
import { EnglishText, SpanishText } from '../utils/localizedText'

const languageState = {...EnglishText}

const language = createContext(languageState)
const { Provider } = language

const reducer = (state, action) => {

    switch(action.type) {
        case 'UPDATE_LANGUAGE':
            if (action.language === 'Spanish') {
                console.log('Setting Spanish')
                return {...state, ...SpanishText}
            }
            if (action.language === 'English') {
                console.log('Setting English')
                return {...state, ...EnglishText}
            }
            else { break }
        default:
            throw new Error()
    }
}
const LanguageProvider = ( { children } ) => {
    const [state, dispatch] = useReducer(reducer, languageState)

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export default LanguageProvider
export const useLanguage = () => useContext(language)