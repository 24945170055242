import React, { useEffect, useState } from 'react'
import UsersScreen from './UsersScreen'
import FacilitiesScreen from './FacilitiesScreen'
import AddUserModal from './AddUserModal'
import { useAdmin } from '../../contexts/AdminCtx'
import '../../css/AdminContainer.css'
import { AnimatePresence } from 'framer-motion'
import { getUserData, getCompanies } from '../../utils/database/dbAdmin'

const AdminContainer = () => {

    const [showModal, setShowModal] = useState(false)
    const adminCtx = useAdmin()
    const { activeTab, tabs, buttonClicked, userData } = adminCtx.state
    const handleMenuClick = (event) => {
        adminCtx.dispatch({type: 'UPDATE_ADMIN_TAB', activeTab: event.target.id})
    }

    useEffect(() => {
        if (buttonClicked) {
            setShowModal(true)
        }
    }, [buttonClicked])

    // GET list of users and companies/locations from database
    useEffect(() => {
        if (userData.length === 0) {
            const updateUserData = async () => {
                try {
                    const result = await getUserData()
                    if (result) {
                        adminCtx.dispatch({type: 'LOAD_USER_DATA', userData: result})
                        console.log(result)
                    }
                    
                } catch (err) {
                    console.log(err)
                    return
                } 
            }
            updateUserData()
        }
    }, [userData])

    useEffect(() => {
        const companyList = async () => {
            try {
                const result = await getCompanies()
                if (result) {
                    adminCtx.dispatch({type: 'LOAD_COMPANY_DATA', companyList: result})
                    console.log(result)
                }
                
            } catch (err) {
                console.log(err)
                return
            } 
        }
        companyList()
    }, [])

    return (
        <div className='AdminContainer'>
            <AnimatePresence>
            {showModal && (
                <AddUserModal key='AddUserModal' setShowModal={setShowModal} />
            )}
            </AnimatePresence>
            <div className='AdminTitle Title'>Admin Portal</div>
            <div className='AdminMenu'>
                {tabs.map((tab) => {
                    return (
                        <button
                            className='AdminButton Shadow'
                            onClick={(e) => handleMenuClick(e)}
                            key={tab}
                            id={tab}
                        >{tab}
                        </button>
                    )
                })}
            </div>
            <div className='AdminWindowContainer'>
                <AnimatePresence exitBeforeEnter>
                    {activeTab === 'Users' && (
                        <UsersScreen key='Users' />
                    )}
                    {activeTab === 'Facilities' && (
                        <FacilitiesScreen key='Facilities' />
                    )}
                </AnimatePresence>
            </div>
        </div>
    )
}

export default AdminContainer