import React, {createContext, useContext, useReducer} from 'react'

const initialState = {
    activeTab: 'Users',
    tabs: ['Users', 'Facilities', 'Menu3', 'Menu4', 'Menu5'],
    userTab: {
        userSelected: null
    },
    buttonClicked: null,
    userData: [],
    companyList: [{}]
}

const admin = createContext(initialState)
const { Provider } = admin

const reducer = (state, action) => {

    switch(action.type) {
        case 'UPDATE_ADMIN_TAB':
            return { ...state, activeTab: action.activeTab }
        
        case 'SELECT_USER':
            return { ...state, userTab: { ...state.userTab, userSelected: action.userSelected }}
        
        case 'BUTTON_CLICKED':
            return { ...state, buttonClicked: action.buttonClicked }

        case 'LOAD_USER_DATA':
            return { ...state, userData: action.userData }
        
        case 'LOAD_COMPANY_DATA':
            return { ...state, companyList: action.companyList}

        case 'RESET_USER_DATA':
            return { ...state, userData: [] }

        default:
            throw new Error()
    }
}
const AdminProvider = ( { children } ) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export default AdminProvider
export const useAdmin = () => useContext(admin)