import React, { Fragment } from 'react'
//import { MdClose } from 'react-icons/md'
const FullPageSpinner = (props) => {

    const { disabled, message } = props

    return (
        <Fragment>
        {disabled === true ? null : (
            <div className='SpinnerContainer'>
                <div className='SpinnerWindow'>
                    <div className="sk-folding-cube">
                        <div className="sk-cube1 sk-cube"></div>
                        <div className="sk-cube2 sk-cube"></div>
                        <div className="sk-cube4 sk-cube"></div>
                        <div className="sk-cube3 sk-cube"></div>
                    </div>
                    <div className='SpinnerLoadingText'>{message}</div>
                </div>
            </div>
        )
        }
        </Fragment>
    )

}

export default FullPageSpinner