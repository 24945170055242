import React from 'react'
import { MdCancel } from 'react-icons/md'
import { useLanguage } from '../../contexts/LanguageCtx'

const InputTable = (props) => {

    const { rows, onRemove } = props
    const language = useLanguage()
    const activeLanguage = {...language.state}

    return (
            <div className='ListCurrentCase'>
                <table id='CurrentCase' style={{width: '100%', cellPadding: '.25rem'}}>
                    <thead>
                        <tr>
                        <th style={{width: '5%'}}>#</th>
                        <th style={{width: '35%'}}>{activeLanguage.TableHeaderFirstScan}</th>
                        <th style={{width: '35%'}}>{activeLanguage.TableHeaderLastScan}</th>
                        <th style={{width: '15%'}}>{activeLanguage.TableHeaderQuantity}</th>
                        <th className='thNoBorder' style={{width: '10%'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        
                    {rows.length > 0 && rows.map((row, index) => 

                        (
                            <tr key={index.toString()} tabIndex={0} style={index % 2 ? {background: '#dddddd'} : {background: 'white'} }>
                                <td>{index + 1}</td>
                                <td>{row.firstCode}</td>
                                <td>{row.lastCode}</td>
                                <td>{row.quantity}</td>
                                <td>{
                                    <button
                                        className='TableButton'
                                        key={`button-${index.toString()}`} 
                                        name='clearRow' 
                                        type='button' 
                                        onClick={() => onRemove(index)}
                                        ><MdCancel size='1.25rem' color='red' />
                                </button>}
                                </td>
                            </tr>
                        )
                    )}
                    
                    </tbody>
                </table>
            </div>
    )
}
    

export default InputTable