// Packages
import React, { useEffect, useState } from 'react'
import axios from 'axios'

// Contexts
import { useStore } from '../../Store'
import { useUser } from '../../contexts/UserCtx'

// Components
import LabelSpinner from './LabelSpinner'

// Utility functions
import getLabelTemplate from '../../utils/print/getLabelTemplate'

/* Component description:  
    The LabelPreview component displays a generated label preview (PNG format) in the Label Preview window.  
    The Labelary API (http://labelary.com) is utilized to generate the image 
*/
const LabelPreview = (props) => {
    
    // Consts
    const caselinkAPI = 'https://caselinkdb.wishfarms.com/api/label/'

    // Props destructure
    const { labelSize } = props

    // Context destructures
    const globalState = useStore()
    const { caseID, caseCode, shortDate, quantity } = globalState.state.labelData
    const user = useUser()
    const { facilityID, facilityName } = user.state
    
    // Component state
    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false)

    // Effect generates preview label (from Labelary API) when caseID or labelSize update
    useEffect(() => {
        async function preview() {
        try {
            setLoading(true)
            const data = getLabelTemplate({ caseID, facilityID, facilityName, shortDate, quantity, caseCode }, labelSize)

            if (data) {
                const testObj = {
                    "data": data,
                    "labelSize": labelSize
                }
                
                const response = await axios.post(caselinkAPI, testObj, {responseType: 'arraybuffer'})
                if (response) {
                    
                    const arrayBufferView = new Uint8Array(response.data)
                    
                    const blob = new Blob([arrayBufferView], {type: 'image/png'})
                    setImage(URL.createObjectURL(blob))
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000)
                    
                }
            }
        } catch (err) { throw err }
        }
        preview()
    }, [caseID, labelSize])

    return (
        <div className='LabelImageContainer'>
            {loading ? (
                <LabelSpinner />
            ) : (
            <img className='LabelImage' src={image} onError={() => <LabelSpinner />} alt='Label Preview' />
            )}
        </div>
    )
}

export default LabelPreview