import React, { Fragment, useState, useEffect } from 'react'
import '../../css/AddUserModal.css'
import { motion } from 'framer-motion'
import { useAdmin } from '../../contexts/AdminCtx'
import IconClose from '../../images/icons/close.svg'
import IconUser from '../../images/icons/user.svg'
import IconPassword from '../../images/icons/key.svg'
import IconConfirm from '../../images/icons/check-mark.svg'
import IconCompany from '../../images/icons/company.svg'
import IconLocation from '../../images/icons/location2.svg'
import IconLanguage from '../../images/icons/chat.svg'
import IconSuccess from '../../images/icons/success.svg'
import { addUser } from '../../utils/database/dbAdmin'

const AddUserModal = (props) => {
    
    const adminCtx = useAdmin()
    const userList = adminCtx.state.userData.map((row) => {
        return row.name
    })
    const { companyList } = adminCtx.state
    const { setShowModal } = props
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [company, setCompany] = useState(0)
    const [companyName, setCompanyName] = useState('')
    const [locations, setLocations] = useState([{}])
    const [location, setLocation] = useState(0)
    const [language, setLanguage] = useState(0)
    const [page, setPage] = useState(1)
    const [usernameWarning, showUsernameWarning] = useState(false)
    const [passwordWarning, showPasswordWarning] = useState(false)
    const [warningText, setWarningText] = useState('')

    const fadeInOut = {
        initial: {opacity: 0},
        animate: {opacity: 1},
        exit: {opacity: 0},
        transition: {duration: .5}
    }

    const handleChange = (e) => {
        if (e.target.name === 'userName') {
            if (usernameWarning) showUsernameWarning(false)
            setUserName(e.target.value)
        }
        if (e.target.name === 'password') {
            if (passwordWarning) showPasswordWarning(false)
            setPassword(e.target.value)
        }
        if (e.target.name === 'confirmPassword') {
            if (passwordWarning) showPasswordWarning(false)
            setConfirmPassword(e.target.value)
        }
        if (e.target.name === 'userCompany') {
            setCompany(parseInt(e.target.value))
        }
        if (e.target.name === 'userLocation') {
            setLocation(parseInt(e.target.value))
        }
        if (e.target.name === 'userLanguage') {
            setLanguage(e.target.value)
        }
    }

    const handleClickOut = (e) => {
        if (e.target.id === 'outsideAddUser') {
            adminCtx.dispatch({type: 'BUTTON_CLICKED', buttonClicked: null})
            setShowModal(false)
        }
    }

    const handleClose = () => {
        adminCtx.dispatch({type: 'BUTTON_CLICKED', buttonClicked: null})
            setShowModal(false)
    }

    const handleSubmit = async () => {
        try {
            const result = await addUser(userName, password, company, companyName, location, language)

            if (result) {
                console.log(result)
                adminCtx.dispatch({type: 'RESET_USER_DATA'})
                setPage(3)
            }
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const nextPage = (event) => {
        event.preventDefault()
        if (password !== confirmPassword) {
            setWarningText('Password values do not match')
            showPasswordWarning(true)
            document.getElementById('userName').focus()
        } else {
            setPage(2)
        }
    }

    const backPage = () => {
        setPage(1)
    }

    const validateUsername = () => {
        const validChars = '^[a-zA-Z0-9]+$'

        if (userList.includes(userName)) {
            setWarningText(`An account with username '${userName}' already exists`)
            showUsernameWarning(true)
            document.getElementById('userName').focus()
        }
        if (userName.length < 4) {
            setUserName('')
            showUsernameWarning(true)
            setWarningText('Username must contain at least 4 characters')
            document.getElementById('userName').focus()
        }
        if (userName.match(validChars)) {
            if (usernameWarning) showUsernameWarning(false)
        } else {
            setUserName('')
            showUsernameWarning(true)
            setWarningText('Username can only contain letters (a-z, A-Z) and numbers (0-9)')
            document.getElementById('userName').focus()
        }
    }

    const validatePassword = () => {
        const validChars = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
        if (!password.match(validChars)) {
            setWarningText('Password must contain at least one: Uppercase, lowercase, number and special character')
            showPasswordWarning(true)
            //document.getElementById('password').focus()
        }
    }
         
    useEffect(() => {
        if (company !== 0) {
            const companyIndex = companyList.findIndex((row) => row.companyID === company)
            const locationData = companyList[companyIndex].locations
            setLocations(locationData)
            setCompanyName(companyList[companyIndex].companyName)
        }
    }, [company, companyList])

    return (
        <motion.div
            key='outsideAddUser'
            id='outsideAddUser' 
            className='AddUserContainer'
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0, transition: { delay: .5}}}
            transition={{ delay: 0, duration: .25}}
            onClick={(e) => handleClickOut(e)}
        >
            <motion.div 
                key='insideAddUser'
                className='AddUserModal' 
                id='insideAddUser'
                initial={{y: '-200%'}}
                animate={{y: 0, transition: { delay: .25, duration: .5}}}
                exit={{y: '-200%', transition: {delay: 0, duration: .5}}}
                
            >
                <div className='AddUserTitle'>
                    <div className='AddUserTitleText FlexCenter'>Add User</div>
                    <button 
                        onClick={handleClose}
                        className='AddUserButtonClose'>
                        <img src={IconClose} alt='Close icon' />
                    </button>
                </div>
                <motion.form 
                    key='AddUserForm'
                    className='AddUserBody'
                    {...fadeInOut}
                >

                {page === 1 && (
                <Fragment>
                    {usernameWarning && (
                        <div className='AddUserWarningUsername'>{warningText}</div>
                    )}
                    {passwordWarning && (
                        <div className='AddUserWarningPassword'>{warningText}</div>
                    )}
                    <img className='AddUserIconUsername' src={IconUser} alt='User icon' />
                    <label className='AddUserLabelUsername'>Username</label>
                    <input 
                        className='AddUserInput AddUserInputUsername InputNoAppend'
                        name='userName'
                        id='userName'
                        type='text'  
                        autoFocus={true}
                        placeholder='Enter username...'
                        onChange={(e) => handleChange(e)}
                        onPaste={(e) => handleChange(e)}
                        onBlur={validateUsername}
                        value={userName}
                        minLength={4}
                        maxLength={25}
                        required
                    />
                    <img className='AddUserIconPassword' src={IconPassword} alt='Password icon' />
                    <label className='AddUserLabelPassword'>Password</label>
                    <input 
                        className='AddUserInput AddUserInputPassword InputNoAppend'
                        name='password'
                        id='password'
                        type='password'  
                        placeholder='Enter password...'
                        onChange={(e) => handleChange(e)}
                        onPaste={(e) => handleChange(e)}
                        onBlur={validatePassword}
                        value={password}
                        minLength={4}
                        maxLength={25}
                        required
                    />
                    <img className='AddUserIconConfirm' src={IconConfirm} alt='Confirm icon' />
                    <label className='AddUserLabelConfirm'>Confirm</label>
                    <input 
                        className='AddUserInput AddUserInputConfirm InputNoAppend'
                        name='confirmPassword'
                        type='password'  
                        placeholder='Confirm password...'
                        onChange={(e) => handleChange(e)}
                        onPaste={(e) => handleChange(e)}
                        value={confirmPassword}
                        minLength={4}
                        maxLength={25}
                        required
                    />
                </Fragment>
                )}
                {page === 2 && (
                <Fragment>
                    <img className='AddUserIconCompany' src={IconCompany} alt='Company icon' />
                    <label className='AddUserLabelCompany'>Company</label>
                    <select 
                        className='AddUserSelect AddUserSelectCompany select-css'
                        name='userCompany'
                        id='userCompany'
                        required={true}
                        value={company}
                        onChange={(e) => handleChange(e)}
                    >   
                        <option value={0} disabled>Select a company...</option>
                        {companyList.map((row, index) => {
                            return (<option key={`Company${index+1}`} value={row.companyID}>{row.companyName}</option>)
                        })}
                    </select>
                    <img className='AddUserIconLocation' src={IconLocation} alt='Location icon' />
                    <label className='AddUserLabelLocation'>Location</label>
                    <select 
                        className='AddUserSelect AddUserSelectLocation select-css'
                        name='userLocation'
                        id='userLocation'
                        required={true}
                        value={location}
                        onChange={(e) => handleChange(e)}
                        disabled={company === 0}
                    >   
                        <option value={0} disabled>Select a location...</option>
                        {locations.map((row, index) => {
                            return <option key={`Location${index+1}`} value={row.locationID}>{`${row.locationID} - ${row.locationCity}, ${row.locationState}`}</option>
                        })}
                    </select>
                    <img className='AddUserIconLanguage' src={IconLanguage} alt='Language icon' />
                    <label className='AddUserLabelLanguage'>Language</label>
                    <select 
                        className='AddUserSelect AddUserSelectLanguage select-css'
                        name='userLanguage'
                        id='userLanguage'
                        required={true}
                        value={language}
                        onChange={(e) => handleChange(e)}
                        disabled={company === 0 || location === 0}
                    >   
                        <option value={0} disabled>Select a language...</option>
                        <option value='English'>English</option>
                        <option value='Spanish'>Spanish</option>
                    </select>
                </Fragment>
                )}
                {page === 3 && (
                    <Fragment>
                        <div className='AddUserIconFinished FlexCenter'><img src={IconSuccess} alt='Success icon' /></div>
                        <div className='AddUserMessageFinished FlexCenter'>User {userName} successfully created!</div>
                    </Fragment>
                )}
                </motion.form>
                {page === 1 && (
                <div 
                    key='ButtonPage1'
                    className='AddUserButtonRow'
                >
                        <motion.button 
                            {...fadeInOut}
                            key='ButtonNext' 
                            className='AddUserButton Button'
                            type='submit'
                            form='AddUserForm'
                            onClick={(e) => nextPage(e)}
                            disabled={userName.length === 0 || password.length === 0 || confirmPassword.length === 0}
                            >Next</motion.button>
                </div>
                )}
                {page === 2 && (
                <div 
                    key='ButtonPage2'
                    className='AddUserButtonRow'
                >
                        <motion.button
                            {...fadeInOut}
                            key='ButtonBack' 
                            className='AddUserButton Button'
                            type='submit'
                            form='AddUserForm'
                            onClick={backPage}
                            >Back</motion.button>
                        <motion.button
                            {...fadeInOut}
                            key='ButtonFinish' 
                            className='AddUserButton Button'
                            type='submit'
                            form='AddUserForm'
                            onClick={handleSubmit}
                            disabled={company === 0 || location === 0 || language === 0}
                            >Finish</motion.button>
                </div>
                )}
                {page === 3 && (
                <div 
                    key='ButtonPage3'
                    className='AddUserButtonRow'
                >
                    <motion.button
                            {...fadeInOut}
                            key='ButtonOK' 
                            className='AddUserButton Button'
                            onClick={handleClose}
                            >OK</motion.button>
                </div>
                )}
            </motion.div>
        </motion.div>
    )
}

export default AddUserModal