import React from 'react'
import { FiPackage } from 'react-icons/fi'
const NavLeft = () => {

    return (
        <div className='NavLeft'>
            <div className='NavLeftLogo'>
                 <FiPackage size='6vh' /> CaseLink Pro
            </div>
        </div>
    )
}

export default NavLeft